<script setup lang="ts">
import { useDebounceFn, usePreferredDark, useStorage, useStyleTag, watchDebounced } from '@vueuse/core'
import TieredMenu from 'primevue/tieredmenu'
import Popover from 'primevue/popover'
import qs from 'qs'

import { useUrlStore } from '~/store/url'

const urlStore = useUrlStore()
const switchLocalePath = useSwitchLocalePath()
await useAsyncData('initializeOptions', () => urlStore.getOptions().then(() => true))

const menu = ref<any>()
const overlayPanel = ref<any>()
const search = ref<string>('')

const viewMode = useStorage<string | null>('viewMode', null)
const preferredDark = usePreferredDark()
const viewModeCss: Record<string, string> = {
  dark: 'html {background-color: rgb(7, 7, 8);} #main-panel {background-color:rgba(24, 24, 27,0.3);backdrop-filter:blur(4px);} ',
  light: 'html {background-color: rgb(255, 255, 255);} #main-panel {background-color:rgba(255, 255, 255,0.3);backdrop-filter:blur(16px);}',
}
const whichStyleTag = viewMode.value !== null ? viewModeCss[viewMode.value] : preferredDark.value ? viewModeCss.dark : viewModeCss.light
const { css } = useStyleTag(whichStyleTag)
if (preferredDark.value || viewMode.value === 'dark') {
  viewMode.value = 'dark'
  document.documentElement.classList.add('dark')
}
watch(viewMode, (value) => {
  if (value === null)
    return
  if (value === 'dark') {
    document.documentElement.classList.add('dark')
  }
  else {
    document.documentElement.classList.remove('dark')
  }
  css.value = value === 'dark' ? viewModeCss.dark : viewModeCss.light
})

watch([() => urlStore.selected.website, () => urlStore.selected.category, () => urlStore.selected.collection, () => urlStore.selected.segment], () => {
  urlStore.getOptions()
})
watchDebounced(() => urlStore.selected.search, () => {
  if (urlStore.selected.search && urlStore.selected.search.length > 2) {
    urlStore.getOptions()
  }
}, { debounce: 500, maxWait: 1000 })

function toggle(event: Event) {
  if (menu.value !== undefined && menu.value !== null)
    menu.value.toggle(event)
}
function toggleOverlayPanel(event: Event) {
  overlayPanel.value?.toggle(event)
}

const getSuggestionsDebounced = useDebounceFn(async () => await urlStore.getSuggestions(), 500)

const chosenStatus = ref<string | undefined>(undefined)
const filteredProducts = computed(() =>
  urlStore.options.products.map(product => ({ ...product, segment: product.segment === 'none' ? ['none'] : product.segment.filter((seg: any) => seg !== 'all') }))
    .filter(product =>
      product.name.toLowerCase().includes(search.value.toLowerCase())
      || product.code.toLowerCase().includes(search.value.toLowerCase())
      || product.segment.includes(search.value.toLowerCase())
      || product.website.toLowerCase().includes(search.value.toLowerCase())
      || product.collection.toLowerCase().includes(search.value.toLowerCase()))
    .filter(product => chosenStatus.value ? product.status === chosenStatus.value : true)
    .filter(product => !product.id.includes('-all')))

const statusOptions = computed(() => [...new Set(urlStore.options.products.map(product => product.status))]
  .filter(Boolean))
</script>

<template>
  <section class="container ">
    <Panel
      id="main-panel"
      :pt="{ content: { style: 'padding:0px;' } }"
      class="w-full lg:h-full lg:w-full lg:mt-6 mx-auto p-0 lg:p-4 min-h-screen lg:min-h-full"
    >
      <template #header>
        <div class="flex align-items-center">
          <h2 class="text-3xl lg:text-6xl font-thin m-0 p-0">
            Product Bulletin
          </h2>
        </div>
      </template>
      <template #icons>
        <div class="flex">
          <Button icon="pi pi-cog" severity="secondary" link class="px-4 py-2 mr-2" @click="toggle" />
          <TieredMenu
            ref="menu"
            :model="[{
              icon: 'pi pi-language',
              label: 'Language',
              items: [
                { label: 'English', command: () => $router.push(switchLocalePath('en')) },
                { label: 'Français', command: () => $router.push(switchLocalePath('fr')) },
              ] }, {
              icon: viewMode === 'dark' ? 'pi pi-moon' : 'pi pi-sun',
              label: $t('menu.appearance'),
              items: [
                { icon: 'pi pi-moon', label: 'Dark', command: () => { viewMode = 'dark' } },
                { icon: 'pi pi-sun', label: 'Light', command: () => { viewMode = 'light' } },
              ],
            }]"
            pt:root:style="min-width:4rem;"
            popup
          />
        </div>
      </template>
      <Stepper value="1">
        <StepItem value="1">
          <Step>
            {{ $t('wizard.stepone') }}
          </Step>
          <StepPanel
            v-slot="{ activateCallback }"
            :pt="{ root: { style: 'background-color: rgba(255, 255, 255,0);' } }"
          >
            <div legend="Filter" class="flex flex-col">
              <div class="flex py-6 gap-8 flex-wrap justify-content-center lg:justify-content-start lg:px-8 align-items-center font-medium">
                <p class="w-full text-zinc-800 dark:text-zinc-400">
                  {{ $t('wizard.steponeparagraph') }}
                </p>
                <Picker
                  v-model:selected="urlStore.selected.website"
                  :options="urlStore.options.websites"
                  :label="$t('website.label')"
                  required
                  name="website"
                />
                <Picker
                  v-model:selected="urlStore.selected.category"
                  :options="urlStore.options.categories"
                  :loading="urlStore.selected.category === null && urlStore.loading"
                  :label="$t('category.label')"
                  required
                  name="category"
                />
                <Picker
                  v-model:selected="urlStore.selected.collection"
                  :options="urlStore.options.collections"
                  :loading="urlStore.selected.collection === null && urlStore.loading"
                  :label="$t('collection.label')"
                  name="collection"
                />
                <Picker
                  v-model:selected="urlStore.selected.segment"
                  :options="urlStore.options.segments"
                  :loading="urlStore.selected.segment === null && urlStore.loading"
                  :label="$t('segment.label')"
                  name="segment"
                />
              </div>
              <Divider pt:content:style="background-color: rgba(0,0,0,0)" type="dashed">
                <strong>{{ $t('menu.or') }}</strong>
              </Divider>

              <div class="flex py-6 gap-5 flex-wrap justify-content-center lg:justify-content-start lg:px-8 align-items-center font-medium">
                <FloatLabel class="w-11/12 lg:w-1/5">
                  <IconField icon-position="right">
                    <InputGroup>
                      <AutoComplete v-model="urlStore.selected.search" :pt="{ root: { style: 'width:224px;' } }" input-id="product-search" :suggestions="urlStore.suggestions" @keyup.enter="() => { if (!urlStore.loading) { activateCallback('2') } }" @clear="urlStore.selected.search = undefined" @complete="getSuggestionsDebounced" />
                      <Button v-if="urlStore.selected.search " icon="pi pi-times" severity="danger" @click="urlStore.selected.search = undefined" />
                    </InputGroup>
                  </IconField>
                  <label for="product-search">
                    <i class="pi pi-search" /> {{ $t('product.search') }}
                  </label>
                </FloatLabel>
              </div>
            </div>
            <div class="flex p-4 lg:px-8 justify-end lg:justify-start">
              <Button icon="pi pi-arrow-right" icon-pos="right" :loading="urlStore.loading" :label="$t('menu.next')" @click="activateCallback('2')" />
            </div>
          </StepPanel>
        </StepItem>
        <StepItem value="2">
          <Step>
            {{ $t('wizard.steptwo') }}
          </Step>
          <StepPanel v-slot="{ activateCallback }" :pt="{ root: { style: 'background-color: rgba(255, 255, 255,0);' }, content: { class: '-ml-5 lg:ml-0 ', style: 'padding-left:0px;' }, toggleableContent: { style: 'background-color:rgba(0,0,0,0)' } }">
            <div class="flex w-full px-4 lg:px-8 gap-2 justify-end lg:justify-content-center">
              <Button class="lg:hidden" icon="pi pi-arrow-left" :label="$t('menu.back')" @click="activateCallback('1')" />
            </div>
            <div class="mt-6 flex flex-wrap px-4 lg:px-32 pb-4">
              <div class="hidden lg:flex flex-inline gap-4 w-full py-2">
                <FloatLabel class="w-full">
                  <InputGroup>
                    <InputText id="search" v-model="search" />
                    <Button v-if="search && search !== null" icon="pi pi-times" severity="danger" @click="search = ''" />
                  </InputGroup>
                  <label class="text-zinc-800 dark:text-white" for="search"><i class="pi pi-search" /> {{ $t('product.search') }}</label>
                </FloatLabel>
                <Picker
                  v-model:selected="chosenStatus"
                  :options="statusOptions"
                  :label="$t('status.label')"
                  name="status"
                />
              </div>
              <div class="w-full flex lg:py-2 justify-end">
                <div v-if="urlStore.hideAllButton" class="w-full flex lg:flex-row-reverse gap-2 justify-between">
                  <Button class="lg:hidden" type="button" severity="secondary" icon="pi pi-search" :label="$t('product.search')" @click="toggleOverlayPanel" />
                  <Popover ref="overlayPanel">
                    <div class="pt-4">
                      <FloatLabel class="w-full">
                        <InputGroup>
                          <InputText id="search" v-model="search" />
                          <Button v-if="search && search !== null" icon="pi pi-times" severity="danger" @click="search = ''" />
                        </InputGroup>
                        <label class="text-zinc-800 dark:text-white" for="search"><i class="pi pi-search" /> {{ $t('product.search') }}</label>
                      </FloatLabel>
                      <Picker
                        v-model:selected="chosenStatus"
                        class="mt-4"
                        :options="statusOptions"
                        :label="$t('status.label')"
                        name="status"
                      />
                    </div>
                  </Popover>
                  <Button v-show="urlStore.options.products.length > 0 && !urlStore.loading && urlStore.selected.products.size !== urlStore.options.products.length" severity="success" @click="urlStore.addAllProducts(filteredProducts)">
                    <i class="pi pi-plus mr-1" style="font-size: 1rem;" />
                    {{ $t('product.selectall') }}
                  </Button>
                  <Button v-show="urlStore.options.products.length > 0 && !urlStore.loading && urlStore.selected.products.size" severity="danger" @click="urlStore.selected.products.clear()">
                    <i class="pi pi-minus mr-1" style="font-size: 1rem;" />
                    {{ $t('product.selectall') }}
                  </Button>
                </div>
              </div>
            </div>
            <div class="flex flex-col  pb-2 pt-2">
              <div v-if="filteredProducts.length > 0 && !urlStore.loading" class="flex flex-wrap gap-4 justify-center align-items-center font-medium px-4">
                <ProductCard
                  v-for="product in filteredProducts"
                  :key="`p-${product.name}-${product.code}-${product.segment}`"
                  :product="product"
                />
              </div>
              <div v-else-if="urlStore.loading" class="flex flex-wrap gap-4 justify-content-center align-items-center font-medium">
                <ProgressSpinner
                  style="width: 50px; height: 50px" stroke-width="8" fill="var(--surface-ground)"
                  animation-duration=".5s" aria-label="Custom ProgressSpinner"
                />
              </div>
              <div v-else class="flex flex-wrap gap-4 justify-content-center align-items-center font-medium">
                <div class="border-2 w-6 text-center border-dashed surface-border border-round surface-ground font-medium px-8 py-4">
                  <p>{{ $t('product.empty') }}</p>
                </div>
              </div>
            </div>
            <div class="flex p-4 gap-2 justify-end lg:justify-content-start">
              <Button icon="pi pi-arrow-left" label="Back" @click="activateCallback(0)" />
            </div>
          </StepPanel>
        </StepItem>
      </Stepper>
    </Panel>
  </section>
</template>

<style scoped>
main {
  min-height: 90vh;
}
</style>
